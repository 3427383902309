var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "LoaderWrapper",
    {
      attrs: {
        loading: _vm.getIsLoadingCallDriver,
        text: "Идет звонок водителю, пожалуйста, подождите!",
      },
    },
    [
      _c("ManagerStatistic", {
        attrs: {
          statistic: _vm.timeslotStatistic,
          "enable-excel": _vm.isExcelButton,
          "enable-deleting": _vm.canMassDelete,
        },
        on: {
          addAuto: _vm.handleAddAuto,
          changeManagerTable: _vm.handleChangeManagerTable,
          createExcelReport: _vm.handleExcelReport,
          onMassDelete: _vm.handleMassDelete,
        },
      }),
      _vm.isMobile
        ? _c(
            "div",
            [
              _c("manager-table-header-mobile", {
                attrs: {
                  "filter-count": _vm.filterCount,
                  "culture-list": _vm.preparedCultureList,
                  "exporters-list": _vm.preparedExportersList,
                  "statuses-list": _vm.entriesStatuses,
                  "total-count": _vm.pagination.totalCount,
                },
                on: {
                  emitSort: _vm.handleSortMobile,
                  addAuto: _vm.handleAddAuto,
                  changeManagerTable: _vm.handleChangeManagerTable,
                  emitFilterParams: _vm.handleFilteredData,
                },
              }),
              _c("manager-list-shipping-mobile", {
                attrs: {
                  "total-count": _vm.pagination.totalCount,
                  list: _vm.preparedTableDataMobile,
                  "is-loading": _vm.getIsLoading,
                },
                on: {
                  handleGetMoreTimeslot: _vm.changePageMobile,
                  editRow: _vm.handleEdit,
                  deleteRow: _vm.handleSingleDelete,
                },
              }),
              !_vm.isExporter
                ? _c(
                    "div",
                    { staticClass: "containerMobile" },
                    [
                      _c("BottomToolbarButton", {
                        attrs: { title: "Добавить авто" },
                        on: { "button-clicked": _vm.handleAddAuto },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("ManagerTableHeader", {
                attrs: {
                  "culture-list": _vm.preparedCultureList,
                  "exporter-list": _vm.suppliersList,
                  "quotas-list": _vm.quotasList,
                },
                on: {
                  emitFilterParams: _vm.handleFilteredData,
                  addAuto: _vm.handleAddAuto,
                  changeManagerTable: _vm.handleChangeManagerTable,
                },
              }),
              _c("ManagerTableBody", {
                attrs: {
                  "table-data": _vm.preparedTableData,
                  "table-fields-desktop": _vm.timeslotsTableFields,
                  "table-fields-tablet": _vm.timeslotsTableFields,
                  "table-fields-mobile": _vm.timeslotsTableFields,
                  "table-fields-small-mobile": _vm.timeslotsTableFields,
                  "table-dropdown-menu-list": _vm.managerTableDropdownMenuList,
                  "is-loading": _vm.getIsLoading,
                },
                on: {
                  editRow: _vm.handleEdit,
                  deleteRow: _vm.handleSingleDelete,
                  selectionChange: _vm.handleMassSelection,
                  selectDropdownMenu: _vm.handleTableMenu,
                  tableSortByKey: _vm.handleSort,
                  forceArrival: _vm.handleForceArrival,
                  goToLogs: _vm.handleGoToLogs,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (slotProps) {
                      return [
                        _c("ManagerTableCell", {
                          attrs: {
                            row: slotProps.data.row,
                            "prop-key": slotProps.data.prop,
                            status: slotProps.data.row.status,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("TableFooter", {
                attrs: {
                  pagination: _vm.pagination,
                  "text-quantity": "Всего автомобилей:",
                },
                on: { page: _vm.changePage, size: _vm.changeSize },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }